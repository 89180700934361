<template>
  <div class="update-donation">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <navigator :items="navigate_items"></navigator>
        </div>
        <div class="col-12">
          <div class="card card-shadow">
            <div class="card-header form-title">{{ titlePage }}</div>
            <div class="card-body">
              <div class="container form-container">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label>Nome</label>
                      <input
                        type="text"
                        disabled
                        class="form-control"
                        v-model="client.name"
                      />
                    </div>
                  </div>
                  <div class="col-12" v-if="formType == 'edit' && client.file_url != null ">
                    <div class="form-group">
                      <label> Gravação </label>
                      <b-form-file
                        ref="fileinput"
                        :disabled="formType == 'edit'"
                        placeholder="Escolha um arquivo"
                        drop-placeholder="Arraste o arquivo aqui..."
                        browse-text="Procurar"
                        v-on:change="uploadRecordFile"
                      ></b-form-file>
                    </div>
                  </div>
                  <div class="col-12" v-if="formType == 'edit' && client.file_url != null ">
                    <a :href="client.file_url" target="_blank" rel="noopener noreferrer">
                      <button  class="btn btn-secondary float-right">
                        Abrir atual
                      </button>
                    </a>
                  </div>
                  <div v-if="formType == 'edit'" class="col-4">
                    <div class="form-group">
                      <label>
                        Valor Antigo da Doação
                        <span class="required-field">*</span>
                      </label>
                      <money
                        class="form-control"
                        disabled
                        v-model="client.old_donation_value"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                  <div v-if="formType == 'edit'" class="col-4">
                    <div class="form-group">
                      <label>
                        Valor Antigo Repasse
                        <span class="required-field">*</span>
                      </label>
                      <money
                        class="form-control"
                        disabled
                        v-model="client.old_donation_transfer"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                  <div v-if="formType == 'edit'" class="col-4">
                    <div class="form-group">
                      <label>
                        Valor Antigo Seguro
                        <span class="required-field">*</span>
                      </label>
                      <money
                        class="form-control"
                        v-bind="money"
                        disabled
                        v-model="client.old_donation_safety"
                        @blur.native="checkDonation()"
                      ></money>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>
                        Valor da Doação
                        <span class="required-field">*</span>
                      </label>
                      <money
                        class="form-control"
                        v-bind:disabled="formType == 'edit'"
                        v-model="client.donation_value"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>
                        Valor Repasse
                        <span class="required-field">*</span>
                      </label>
                      <money
                        class="form-control"
                        v-bind:disabled="formType == 'edit'"
                        v-model="client.donation_transfer"
                        v-bind="money"
                      ></money>
                    </div>
                  </div>
                  <div class="col-4">
                    <div class="form-group">
                      <label>
                        Valor Seguro
                        <span class="required-field">*</span>
                      </label>
                      <money
                        v-bind:disabled="formType == 'edit'"
                        class="form-control"
                        v-bind="money"
                        v-model="client.donation_safety"
                        @blur.native="checkDonation()"
                      ></money>
                    </div>
                  </div>
                  
                  <div v-if="formType == 'edit'" class="col-12">
                    <div class="form-group">
                      <label>Operator</label>
                      <input
                        type="text"
                        disabled
                        v-model="client.user.name"
                        class="form-control"
                      />
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label>Descrição</label>
                      <textarea
                        v-bind:disabled="formType == 'edit'"
                        cols="30"
                        rows="6"
                        class="form-control"
                        v-model="client.description"
                      ></textarea>
                    </div>
                  </div>
                  <div v-if="formType == 'create'" class="col-12">
                    <button
                      class="btn btn-primary float-right btn-submit"
                      v-on:click="save()"
                    >
                      Salvar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { bus } from "@/main";
import helper from "@/imports/Helpers";
import validator from "@/imports/validator/validate";
import update_donation_validate from "@/imports/validator/models/update_donation";
import { Money } from "v-money";

export default {
  name: "update-donation",
  components: {
    Money,
  },
  props: {
    id: Number,
    type: String,
  },
  data() {
    return {
      client: {
        files: [],
        user: [],
      },
      formType: "create",
      titlePage: "Nova Atualização",
      newFile: {},
      refreshCondition: false,
      money: {
        decimal: ",",
        thousands: ".",
        prefix: "R$ ",
        precision: 2,
        masked: false,
      },
      navigate_items: [
        {
          text: "Clientes",
          route: "dashboard.clients",
        },
        {
          text: "Criar",
          active: true,
        },
      ],
    };
  },
  mounted() {
    if (this.id && this.type == "create") {
      this.formType = "create";
      this.getDataObjectClient(this.id);
    } else {
      this.formType = "edit";
      this.navigate_items[1].text = "Visualizar";
      this.getDataObject(this.id);
    }
  },
  methods: {
    getDataObjectClient: function () {
      bus.$emit("changeLoading", true);
      helper.getDataObject("clients", this.id).then((response) => {
        this.client = response.data;
        bus.$emit("changeLoading", false);
      });
    },
    getDataObject: function () {
      bus.$emit("changeLoading", true);
      helper
        .getDataObject("clients/get_operation", this.id)
        .then((response) => {
          this.client = response.data;
          this.client.name = this.client.client.name;
          this.titlePage = this.client.name;
          this.client.donation_transfer = this.client.new_donation_transfer;
          this.client.donation_safety = this.client.new_donation_safety;
          this.client.donation_value = this.client.new_donation_value;
          bus.$emit("changeLoading", false);
        });
    },
    save: function () {
      let errors = validator.validate(
        this.client,
        update_donation_validate.model
      );
      if (errors.length == 0) {
        let data = JSON.parse(JSON.stringify(this.client));
        data.record_file = this.client.record_file;
        helper
          .saveCustomFormDataObject(
            "clients",
            "update_donation",
            data,
            this.formType,
            "Atualização",
            "a"
          )
          .then(() => {
            this.$router.push({ name: "dashboard.clients" });
          });
      } else {
        helper.showErrorsForm(errors);
      }
    },
    addFile: function () {
      this.client.files.push(this.newFile.file);
      this.newFile = {};
      this.$refs.newfileinput.reset();
    },
    uploadFile: function (e) {
      const file = e.target.files[0];
      if (e.target.files[0].size / 1000000 > 50) {
        this.$refs.newfileinput.reset();
        Swal.fire({
          title: "O tamanho máximo permitido é 50MB.",
          icon: "warning",
        });
        return;
      } else {
        this.newFile.file = file;
        this.refreshCondition = !this.refreshCondition;
      }
    },
    uploadRecordFile: function (e) {
      const file = e.target.files[0];
      if (!file.type.includes("audio/")) {
        this.$refs.fileinput.reset();
        Swal.fire({
          title: "Este arquivo não é suportado. Ele não será importado.",
          icon: "warning",
        });
        return;
      } else if (e.target.files[0].size / 1000000 > 50) {
        this.$refs.fileinput.reset();
        Swal.fire({
          title: "O tamanho máximo permitido é 50MB.",
          icon: "warning",
        });
        return;
      } else {
        this.client.record_file = file;
      }
    },
    checkDate: function () {
      if (!helper.validateDateBR(this.client.call_date)) {
        this.client.call_date = "";
        Swal.fire({
          title: "Insira uma data válida",
          icon: "warning",
        });
      }
    },
    checkDonation: function () {
      if (
        this.client.donation_safety != null &&
        this.client.donation_transfer != null &&
        this.client.donation_value != null
      ) {
        if (
          this.client.donation_safety + this.client.donation_transfer !=
          this.client.donation_value
        ) {
          this.client.donation_safety = "";
          this.client.donation_transfer = "";
          Swal.fire({
            title:
              "A soma dos campos Valor Repasse e Valor Seguro é maior que do Valor de Doação",
            icon: "warning",
          });
        }
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/general.scss";

.call {
  .name-cell {
    width: 90%;
  }
  .remove-cell {
    width: 10%;
  }
  .col-table {
    padding-top: 28px;
  }
  .btn-add-file {
    margin-left: 8px;
  }
  .actions-btn {
    button {
      margin-left: 3px;
      margin-right: 3px;
    }
  }
  .form-control-search {
    .search {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da !important;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
  }
}
</style>