export default {
    model: [
      {
        label: 'Valor da Doação',
        field: 'donation_value',
        type: 'text'
      },
      {
        label: 'Valor Repasse',
        field: 'donation_transfer',
        type: 'text'
      },
    ]
  }